import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { sharedWrapperPadding } from './sharedWrapperPadding';

export const StyledSectionsWrapper = styled.div<{ resetOverflow?: boolean }>`
	overflow-x: hidden;
	// Todo: this statement, including overflow-x: hidden should be removed when overflow issue is fixed
	// https://moveagency.atlassian.net/browse/DFW-3459
	${({ resetOverflow }) =>
		resetOverflow &&
		css`
			overflow-x: initial;
		`}

	section:last-of-type {
		padding-bottom: 0;
	}

	${sharedWrapperPadding}
`;
